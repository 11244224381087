import React, { useEffect } from 'react'
import { logout } from '../../services/user/user-query'
import { useRouter } from '../../utils/helper'

const Logout = () => {
  const { query } = useRouter<{ is_logout?: any }>()
  useEffect(() => {
    if (query.is_logout === "true") {
      logout()
    }
  }, [query])

  return null
}

export default Logout